import React from "react";
import { RouteObject, createBrowserRouter } from 'react-router-dom'
import Root from './layouts/Root';

// Pages
const Home = React.lazy(() => import('./pages/Home'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Blogs = React.lazy(() => import('./pages/Blogs'));
const MDParser = React.lazy(() => import('./components/MDParser'));
const Blog = React.lazy(() => import('./pages/Blog'));
const Service = React.lazy(() => import('./pages/Service'));
const Services = React.lazy(() => import('./pages/Services'));
const ScheduleCall = React.lazy(() => import('./pages/ScheduleCall'));
const Chat = React.lazy(() => import('./pages/Chat'));


const routes: RouteObject[] = [
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: '',
                element: <Home />
            },

            // Contact Us Page
            {
                path: 'contact-us',
                element: <ContactUs />
            },
            {
                path: 'about',
                element: <MDParser url='about' markdownFileLoc='/static/MD/AboutUs.md' title='About Us' />
            },

            // TODO: Complete Services Pages
            {
                path: 'services',
                element: <Services />
            },
            {
                path: 'services/:slug',
                element: <Service />
            },

            // TODO: Complete Portfolio Pages
            // TODO: FAQ Pages
            // TODO: Case Studies Pages 
            // {
            //     path: 'portfolio',
            //     element: <div children="portfolio" />
            // },


            // Blog Pages
            {
                path: 'blog/:id',
                element: <Blog />
            },
            {
                path: 'blog',
                element: <Blogs />
            },

            // Legal Terms and Conditions Pages
            {
                path: 'privacy',
                element: <MDParser markdownFileLoc='/static/MD/Privacy.md' title='Privacy Policies' url='privacy' />
            },
            {
                path: 'terms',
                element: <MDParser markdownFileLoc='/static/MD/Privacy.md' title='Privacy Policies' url='terms' />
            },
            {
                path: 'cookies',
                element: <MDParser markdownFileLoc='/static/MD/Cookies.md' title='Cookie Policies' url='cookies' />
            },
            {
                path: 'returns',
                element: <MDParser markdownFileLoc='/static/MD/Returns.md' title='Return Policies' url='returns' />
            },
            {
                path: 'disclaimers',
                element: <MDParser markdownFileLoc='/static/MD/Disclaimers.md' title='DISCLAIMERS' url='disclaimers' />
            }
        ]
    },
    // TODO: Complete Chatbot Pages
    // TODO: Complete Schedule a Call Pages
    {
        path: 'schedule-call',
        element: <ScheduleCall />
    },
    {
        path: 'chat',
        element: <Chat />
    },

    {
        path: '*',
        element: <NotFound />
    }
]


const router = createBrowserRouter(routes, {
    basename: '',
    future: {
        'v7_fetcherPersist': true,
        'v7_normalizeFormMethod': true,
        'v7_partialHydration': true
    }
})

export default router;