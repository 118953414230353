import { Box, BoxProps, useTheme } from "@mui/material";


interface LogoProps extends BoxProps<'svg'> {
    dark?: boolean;
    hideBg?: boolean;
    dimBg?: boolean;
    customIconColor?: string;
    customIconStroke?: number;
    maskText?: boolean;
};


const Logo = ({ maskText = false, customIconColor, dimBg = false, customIconStroke = 4, hideBg = false, dark, ...props }: LogoProps) => {
    const theme = useTheme();

    return (
        <Box component='svg' viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props} >
            <rect width="512" height="512" rx="96" opacity={hideBg ? 0 : dimBg ? 0.66 : 1} fill="url(#paint0_linear_358_92)" />
            <path
                fill={maskText ? "url(#paint0_linear_358_92)" : undefined}
                d="M204.933 403.5H106.737L204.664 256.609L205.402 255.502L204.666 254.393L106.73 107H205.428L304.096 255.495L204.933 403.5Z"
                stroke={customIconColor ?? theme.palette.background.default}
                stroke-width={customIconStroke}
            />
            <path
                fill={maskText ? "url(#paint0_linear_358_92)" : undefined}
                d="M308.5 403.377V107.588L407.096 255.004L308.5 403.377Z"
                stroke={customIconColor ?? theme.palette.background.default}
                stroke-width={customIconStroke}
            />
            <defs>
                <linearGradient id="paint0_linear_358_92" x1="495.333" y1="26.6667" x2="28.6667" y2="508.667" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F7DB27" />
                    <stop offset="0.4" stop-color="#64B0AE" />
                    <stop offset="0.6" stop-color="#64B0AE" />
                    <stop offset="1" stop-color="#7E538D" />
                </linearGradient>
            </defs>
        </Box>
    )
};

export default Logo;