import React from 'react';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';

import Logo from '../svgs/Logo';
import LinkButtons from '../constants/linkButtons';


export default function Footer() {
    return (
        <Box component='footer' sx={{ p: 2, textAlign: 'center' }}>

            <Grid
                container
                spacing={2}
                sx={{
                    "& .MuiGrid-item": {
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        display: 'flex',
                        flexDirection: 'column',
                        pY: '2rem',
                        px: '1rem',
                    },
                    "& .MuiTypography-h6": {
                        textAlign: 'left',
                        fontSize: {
                            xs: '18px', md: '21px', lg: '27px'
                        },
                    }
                }}
            >

                <Grid item xs={12} md={4} >
                    <Logo width='60px' height='60px' my={2} />
                    <Typography
                        textAlign='left'
                        variant='body1'
                        color='text.disabled'
                        children='Beyond Digital is a digital marketing agency based in India. We specialize in SEO, Social Media Marketing, and Web Development.'
                    />
                </Grid>

                <Grid
                    item
                    xs={6}
                    md={4}
                    sx={{
                        "& .MuiLink-root": {
                            transition: 'color 0.5s',
                            textAlign: 'left',
                            fontWeight: '400',
                            fontSize: { xs: '30px', md: '36px', lg: '45px' },
                            color: 'text.primary',
                            textDecoration: 'none',
                            flex: 1,
                            "&:hover": {
                                color: 'primary.main'
                            }
                        }
                    }}
                    children={LinkButtons.map((button, index) => (<Link key={index} href={button.link} variant='body1'>{button.title}</Link>))}
                />

                <Grid
                    item xs={12} md={4}
                    sx={{
                        "& .MuiLink-root": {
                            transition: 'color 0.5s',
                            textAlign: 'left',
                            fontWeight: '400',
                            fontSize: { xs: '12px', md: '14px', lg: '16px' },
                            color: 'text.disabled',
                            textDecoration: 'none',
                            my: 0.5,
                            "&:hover": {
                                color: 'primary.light'
                            }
                        }
                    }}
                >
                    <Grid container spacing={2} justifyContent='center'>
                        <Grid item xs={6} >
                            <Typography variant='h6'>Policies</Typography>
                            <Link href='https://card.jaesmetaverse.com' target='_blank' variant='body1'>Portfolio</Link>
                            <Link href='/privacy' variant='body1'>Privacy</Link>
                            <Link href='/cookies' variant='body1'>Cookies</Link>
                            <Link href='/disclaimers' variant='body1'>Disclaimers</Link>
                            <Link href='/terms' variant='body1'>Terms of Service</Link>
                            <Link href='/returns' variant='body1'>Return Policies</Link>
                        </Grid>
                        <Grid item xs={6} >
                            <Typography variant='h6'>Contact Us</Typography>
                            <Link href='tel:+916359337591' children='Call' />
                            <Link href='mailto:founder@beyondigital.in' children='E-Mail' />
                            <Link
                                href='https://wa.me/+916359337591'
                                children='Whatsapp'
                                target='_blank'
                            />
                            <Link href='/contact-us'>
                                Request a Call
                            </Link>
                            <Link href='/chat' children="Chat with Beyond's AI" />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent='space-between' alignItems='center' sx={{ bgcolor: 'background.default', color: 'text.disabled', p: 2, mb: '80px' }} >
                <Typography textAlign='center' variant='body1'>© Beyond Digital 2021 - {new Date().getFullYear().toString()} All rights reserved</Typography>
                <Stack direction='row' spacing={2} justifyContent='space-between' sx={{ "*": { bgcolor: 'background.default', color: 'text.disabled', p: 2 } }} >
                    <Typography variant='body1' component={Link} href='/privacy' >Privacy Policy</Typography>
                    <Typography variant='body1' component={Link} href='/terms' >Terms of Service</Typography>
                </Stack>
            </Stack>

        </Box>
    );

};
