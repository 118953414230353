import { createTheme, Theme } from '@mui/material/styles';


const components: Theme['components'] = {
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: '4px', // Button border radius
                transition: 'all 0.3s ease', // Button hover transition
            },
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                backgroundImage: 'linear-gradient(279deg, rgb(161, 94, 232) 0%, rgb(109, 141, 255) 100%)', // Gradient background color
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                borderRadius: '4px', // Chip border radius
                backgroundColor: 'background.paper', // Background color
                textTransform: 'uppercase', // Uppercase text
            },
        },
    },
    MuiContainer: {
        defaultProps: {
            maxWidth: "md", // Default container max width
        }
    },
    MuiTypography: {
        defaultProps: {
            "fontFamily": 'Lato'
        },
        styleOverrides: {
            h1: {
                fontFamily: 'Lato, serif',
                fontWeight: 700,
                fontSize: '3rem',
                color: 'rgb(34, 34, 34)',
                marginBottom: '0.5rem',
            },
            h2: {
                fontFamily: 'Lato, serif',
                fontWeight: 700,
                fontSize: '2.5rem',
                color: 'rgb(34, 34, 34)',
                marginBottom: '0.5rem',
            },
            h3: {
                fontFamily: 'Lato, serif',
                fontWeight: 700,
                fontSize: '2rem',
                color: 'rgb(34, 34, 34)',
                marginBottom: '0.5rem',
            },
            h4: {
                fontFamily: 'Lato, serif',
                fontWeight: 700,
                fontSize: '1.75rem',
                color: 'rgb(34, 34, 34)',
                marginBottom: '0.5rem',
            },
            h5: {
                fontFamily: 'Lato, serif',
                fontWeight: 700,
                fontSize: '1.5rem',
                color: 'rgb(34, 34, 34)',
                marginBottom: '0.5rem',
            },
            h6: {
                fontFamily: 'Lato, serif',
                fontWeight: 700,
                fontSize: '1.25rem',
                color: 'rgb(34, 34, 34)',
                marginBottom: '0.5rem',
            },
            subtitle1: {
                fontFamily: 'Lato, sans-serif',
                fontWeight: 400,
                fontSize: '1rem',
                color: 'rgb(105, 105, 105)',
            },
            subtitle2: {
                fontFamily: 'Lato, sans-serif',
                fontWeight: 400,
                fontSize: '0.875rem',
                color: 'rgb(105, 105, 105)',
            },
            body1: {
                fontFamily: 'Lato, sans-serif',
                fontWeight: 400,
                fontSize: '1rem',
                color: 'rgb(51, 51, 51)',
            },
            body2: {
                fontFamily: 'Lato, sans-serif',
                fontWeight: 400,
                fontSize: '0.875rem',
                color: 'rgb(51, 51, 51)',
            },
            button: {
                fontFamily: 'Lato, sans-serif',
                fontWeight: 700,
                textTransform: 'uppercase',
            },
            caption: {
                fontFamily: 'Lato, sans-serif',
                fontWeight: 400,
                fontSize: '0.75rem',
                color: 'rgb(128, 128, 128)',
            },
            overline: {
                fontFamily: 'Lato, sans-serif',
                fontWeight: 400,
                fontSize: '0.75rem',
                textTransform: 'uppercase',
                color: 'rgb(128, 128, 128)',
            }
        }
    },
    MuiCssBaseline: {
        styleOverrides: {
            body: {
                backgroundColor: 'rgb(255, 255, 255)', // Body background color
            },
            strong: {
                fontWeight: 700, // Bold text weight
            }
        },
    },
}

// Light theme colors
const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#573CFF', // Primary color
        },
        secondary: {
            main: '#fbbc04', // Secondary color
        },
        text: {
            primary: '#202124', // Primary text color
            secondary: '#5f6368', // Secondary text color
        },
        background: {
            default: '#ffffff', // Default background color
            paper: '#f1f3f4', // Paper background color
        },
    },
    components
});



export { lightTheme };
