import React from 'react';
import ReactDOM from 'react-dom/client';

import { RouterProvider } from 'react-router-dom';
import { RelayEnvironmentProvider } from 'react-relay';

import reportWebVitals from './reportWebVitals';

import router from './routes';
import RootLoader from './loader/Root';
import ThemeWrapper from './theme/Wrapper';
import { lightTheme } from './theme';
import { environment } from './constants/network';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <React.Suspense fallback={<RootLoader />} >
      <ThemeWrapper darkTheme={lightTheme} lightTheme={lightTheme} >
        <RelayEnvironmentProvider environment={environment} >
          <RouterProvider router={router} />
        </RelayEnvironmentProvider>
      </ThemeWrapper>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
