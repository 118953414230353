import React from 'react';
import { useNavigate } from 'react-router';
import { AppBar, Backdrop, Box, Button, Fab, IconButton, keyframes, Paper, Stack, SxProps, Theme, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ArrowOutward, AutoAwesomeSharp, BlurOnSharp, Call, ContactPage, Email, Instagram, LinkedIn, WhatsApp } from '@mui/icons-material';

// 
import Logo from '../svgs/Logo';
import Hamburger from './Hamburger';
import LinkButtons from '../constants/linkButtons';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

export const ConnectWithUs = (
    {
        children = "Connect with us",
        navTo = "/contact-us"
    }: {
        children?: string;
        navTo?: string;
    }
) => {
    const nav = useNavigate();

    return (
        <Button
            onClick={() => nav(navTo)}
            variant='outlined'
            sx={{
                color: "background.default",
                borderColor: "background.default",
                fontSize: "1.2rem",
                height: "45px",
                fontWeight: 500,
                textTransform: "none",
                borderRadius: "9px",
                mx: 1,
                py: "10px",
                px: "20px",
                "&:hover": {
                    scale: 1.05
                },
            }}
            children={children}
            endIcon={<ArrowOutward />}
        />
    )
};

const SlideFromLeft = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
`;
const SlideFromRightBottom = keyframes`
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
`;

const ContentXS = () => {
    const [open, setopen] = React.useState<boolean>(false);
    // height and width of the screen
    const nav = useNavigate();
    const theme = useTheme();

    const _toggle = React.useCallback((value?: boolean) => { setopen(value === undefined ? !open : value); }, [open, setopen]);

    const _nav = React.useCallback((link: string) => { nav(link); _toggle(false); }, [nav, _toggle]);
    const _l = [
        ...LinkButtons,
        {
            title: "Beyond AI",
            link: "/chat",
            icon: <AutoAwesomeSharp />
        },
        {
            title: "Contact Us",
            link: "/contact-us",
            icon: <ContactPage />
        }
    ];

    return (
        <React.Fragment>
            <Box
                component='span'
                sx={{
                    position: 'fixed',
                    zIndex: 997,
                    bgcolor: 'background.default',
                    top: 0,
                    left: '90px',
                    right: 0,
                    height: '12px',
                    borderRadius: '0 0 14px 0',
                    boxShadow: `0 0 10px 0 ${theme.palette.divider}`,
                }}
            />

            <Stack
                direction="row"
                spacing={1}
                sx={{
                    "& .MuiFab-root": {
                        position: 'fixed',
                        bgcolor: 'background.default',
                        transition: 'all 0.5s ease-in-out',
                        boxShadow: `0 0 10px 0 ${theme.palette.divider}`,
                        "&:hover": {
                            bgcolor: theme.palette.background.default,
                            boxShadow: `0 0 10px 0 ${theme.palette.divider}`,
                            transition: 'all 0.5s ease-in-out'
                        }
                    }
                }}
            >

                <Fab
                    sx={{ left: 0, borderRadius: '0 0 14px 0', top: 0, zIndex: 997, width: '90px', height: '90px' }}
                    onClick={() => _nav('/')}
                >
                    <Logo
                        id='logo'
                        mx={2}
                        px='12px'
                        width='108px'
                        height='108px'
                        position='fixed'
                        customIconColor={theme.palette.text.primary}
                        hideBg
                        maskText
                        onClick={() => _nav("/")}
                    />
                </Fab>

                <Backdrop open={open} sx={{ zIndex: 998, backdropFilter: "blur(9px)", left: -8 }} />

                <Paper
                    component={Stack}
                    direction='column'
                    sx={{
                        flex: 1,
                        bgcolor: 'background.default',
                        borderRadius: '14px 0 0 14px',
                        overflow: 'hidden',
                        boxShadow: `0 0 10px 0 ${theme.palette.divider}`,
                        top: '12px',
                        right: '0px',
                        bottom: '80px',
                        position: 'fixed',
                        margin: '0px',
                        maxWidth: '450px',
                        width: 'calc(100vw - 18px)',
                        transition: 'all 0.5s ease-in-out',
                        animation: `${SlideFromRightBottom} 0.5s ease-in-out`,
                        display: open ? 'flex' : 'none',
                        zIndex: 999,
                        p: 3,
                        alignItems: 'flex-start',
                        "&::-webkit-scrollbar": {
                            display: 'none',
                        },
                    }} >

                    <Typography variant='h1' fontWeight='bold' fontSize={21} color='text.primary' onClick={() => _nav('/')}>Beyond Digital</Typography>

                    <Stack
                        flex={1}
                        direction='column'
                        alignItems='flex-start'
                        sx={{
                            flex: 1,
                            overflowY: 'scroll',
                            display: 'flex',
                            py: 1,
                            "&::-webkit-scrollbar": {
                                display: 'none',
                            },
                        }}
                        children={_l.map((v, i) => {
                            const isActive = window.location.pathname.toString() === v.link;
                            return (
                                <Button
                                    key={i}
                                    disableRipple
                                    variant='text'
                                    sx={{
                                        display: 'flex',
                                        textAlign: 'left',
                                        fontSize: isActive ? '18px' : '15px',
                                        opacity: isActive ? 1 : 0.75,
                                        ml: isActive ? '12px' : 0,
                                        color: isActive ? 'primary.main' : 'text.primary',
                                        padding: '9px 12px',
                                        "&:hover": {
                                            bgcolor: theme.palette.background.default,
                                            color: theme.palette.primary.main,
                                            opacity: 1,
                                            transition: 'all 0.5s ease-in-out'
                                        },
                                        'svg': {
                                            width: '18px',
                                            height: '18px',
                                        }
                                    }}
                                    onClick={() => _nav(v.link)}
                                    children={v.title}
                                    startIcon={v.icon ?? <BlurOnSharp />}
                                    size={isActive ? 'large' : 'medium'}
                                />
                            )
                        })}
                    />

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        flexWrap='wrap'
                        spacing={2}
                        sx={{
                            width: '100%',
                            "& .MuiIconButton-root": {
                                bgcolor: 'background.default',
                                padding: '12px',
                                borderRadius: '25%',
                                'svg': {
                                    fontSize: '21px'
                                }
                            }
                        }}
                    >
                        <IconButton
                            href='tel:+916359337591'
                            children={<Call />}
                            color='success'
                        />
                        <IconButton
                            href='mailto:founder@beyondigital.in'
                            children={<Email />}
                            color='info'
                        />
                        <IconButton
                            children={<WhatsApp />}
                            href='https://wa.me/+916359337591'
                            color='success'
                        />
                        <IconButton
                            href='https://instagram.com/beyondigital.ai'
                            children={<Instagram />}
                        />
                        <IconButton
                            href='https://linkedin.com/compnay/beyond-digital-technologies'
                            children={<LinkedIn />}
                            color='info'
                        />
                    </Stack>

                </Paper>

                <Fab
                    onClick={() => _toggle()}
                    sx={{
                        right: '15px',
                        bottom: '15px',
                        zIndex: 999,
                    }}
                >
                    <Hamburger open={open} >
                        <span />
                        <span />
                        <span />
                    </Hamburger>
                </Fab>

            </Stack>
        </React.Fragment>
    );
};

const ContentMD = () => {
    const nav = useNavigate();
    const theme = useTheme();

    return (
        <React.Fragment>
            <Toolbar
                px="20px" component={Stack} direction="row" height="100%" width="100%" alignItems="center" justifyContent="space-between"
                sx={{
                    "& #logo": {
                        bgcolor: theme.palette.background.default,
                        borderRadius: '0 0 14px 14px',
                        boxShadow: `0 0 10px 0 ${theme.palette.divider}`,
                        width: "72px",
                        height: "66px",
                        mb: "6px",
                    },
                    "& #title": {
                        opacity: 0,
                        transition: 'opacity 0.5s ease-in-out',
                        mx: '2px',
                    },
                    ":hover": {
                        "& #title": {
                            color: theme.palette.primary.main,
                            opacity: 1,
                            animation: `${SlideFromLeft} 0.5s ease-in-out`
                        },
                        "& #logo": {
                            bgcolor: 'transparent',
                            boxShadow: 'none',
                        }
                    },
                    '& .MuiButton-outlined, .MuiButton-text': {
                        color: theme.palette.background.default,
                        borderColor: theme.palette.background.default,
                    },
                }}
            >
                {/* logo button */}
                <Stack direction="row" alignItems="center" spacing={1} >
                    <Logo
                        id='logo'
                        hideBg
                        mx={2}
                        px='12px'
                        customIconStroke={32}
                        customIconColor={theme.palette.text.primary}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => nav("/")}
                    />
                    <Typography id='title' component="h1" variant='h1' fontWeight='bold' fontSize={21} color='text.primary' onClick={() => nav("/")}>Beyond Digital</Typography>
                </Stack>

                {/* navigation buttons */}
                <Stack direction="row" flex={1}>
                    <Stack className='navbtns' direction="row" spacing={1} mx='auto' sx={{ color: theme => theme.palette.background.default }} >
                        {LinkButtons.map((v, i) => {
                            const isActive = window.location.pathname.toString() === v.link;
                            return (
                                <Button
                                    key={i}
                                    disableRipple
                                    variant='text'
                                    sx={{ opacity: isActive ? 1 : 0.75, color: isActive ? 'primary.main' : 'text.primary' }}
                                    onClick={() => nav(v.link)}
                                    children={v.title}
                                />
                            )
                        })}
                    </Stack>
                    <ConnectWithUs />
                </Stack >
                {/*  */}

            </Toolbar>
        </React.Fragment>
    );
};

function Header() {
    // const theme = useTheme();
    const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [blur, setBlur] = React.useState<boolean>(false);
    const xprops: (theme: Theme) => SxProps<Theme> = (theme) => ({
        '& #logo': { bgcolor: 'transparent', boxShadow: 'none' },
        bgcolor: theme => theme.palette.background.default,
        '& .navbtns': {
            transform: 'scale(1.2)',
            transition: 'all 0.5s ease-in-out',
        },
        '& .MuiButton-text': {
            color: theme.palette.text.secondary,
            "&:hover": {
                color: theme => theme.palette.primary.main,
                bgcolor: 'transparent',
            }
        },
        '& .MuiButton-outlined': {
            borderColor: theme => theme.palette.primary.main,
            color: theme => theme.palette.primary.main,
            "&:hover": {
                bgcolor: theme => theme.palette.primary.main,
                color: theme => theme.palette.background.default,
                paddingX: '30px'
            }
        }
    });
    React.useEffect(() => {
        const main = document.getElementById('main');
        const handleScroll = () => {
            if (main) {
                const top = main.scrollTop;
                if (top < 72) {
                    setBlur(false);
                } else {
                    setBlur(true);
                }
            }
        };
        main?.addEventListener('scroll', handleScroll);
        return () => main?.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <React.Fragment>
            <AppBar
                sx={[theme => ({
                    width: "100%",
                    height: "72px",
                    bgcolor: "transparent",
                    backgroundImage: 'none',
                    boxShadow: "none",
                    top: 0,
                    [theme.breakpoints.up('md')]: {
                        '&:hover': xprops(theme),
                        ...(blur && {
                            ...xprops(theme),
                            bgcolor: 'rgba(255,255,255,0.25)',
                            backdropFilter: "blur(9px)",
                            boxShadow: `0px 0px 10px ${theme.palette.divider}`,
                            zIndex: 999,
                            transition: 'all 0.5s ease-in-out',
                            background: `linear-gradient(to bottom, ${theme.palette.background.default} 0%, transparent 100%)`,
                        }),
                    }
                })]}
                position='fixed'
                children={xs ? <ContentXS /> : <ContentMD />}
            />

            <Fab
                size='large'
                sx={{
                    boxShadow: theme => `0 2px 4px ${theme.palette.divider}`,
                    bgcolor: 'background.paper',
                    position: 'fixed',
                    right: '15px',
                    bottom: '15px',
                    zIndex: 999,
                    height: '72px',
                    width: '72px',
                    display: { xs: 'none', md: 'flex' },
                }}
                href='/chat'
                children={
                    <DotLottieReact
                        src={'/static/lottie/ai.lottie'}
                        autoplay
                        loop
                    />
                }
            />
        </React.Fragment>
    );

};

export default Header;
