import React from 'react';
import { Box, keyframes, Typography, useTheme } from '@mui/material';
import Logo from '../svgs/Logo';

const animate = keyframes`
    0%, 100% {
        transform: scale(1);
        rotate: 0deg;
    }
    50% {
        transform: scale(1.1);
        rotate: 180deg;
    }
    100% {
        transform: scale(1);
        rotate: 360deg;
    }
`;

export default function RootLoader() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: theme => theme.palette.background.paper,
                flexDirection: 'column'
            }}>
            <Logo
                dark={theme.palette.mode === 'dark'}
                width="256px"
                height="256px"
                borderRadius='32px'
                sx={{ objectFit: 'fill', m: 10, animation: `${animate} 2s infinite` }}
            />
            <Typography
                variant='caption'
                children='® Beyond Digital 2024, All rights Reserved'
            />
        </Box>
    );
};
