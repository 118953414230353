import React from 'react';

function useWindowDimensions() {
    const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth });

    React.useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return dimensions;
};

export default useWindowDimensions;