import { DesignServices, Info, Stream, VideoCall } from "@mui/icons-material";

type ButtonLink = {
    title: string; link: string; icon?: React.ReactNode;
};

const LinkButtons: Array<ButtonLink> = [
    // { title: 'Home', link: '/', icon: <Home /> },
    { title: 'Services', link: "/services", icon: <DesignServices /> },
    { title: 'About', link: "/about", icon: <Info /> },
    { title: 'Blog', link: "/blog", icon: <Stream /> },
    { title: 'Schedule a Call', link: "/schedule-call", icon: <VideoCall /> },
]


export default LinkButtons;