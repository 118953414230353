import React from 'react';
import { Box, SxProps, Theme, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router';
import Header from '../components/Header';
import Footer from '../components/Footer';
import useWindowDimensions from '../hooks/useDimensions';


const radSx: SxProps = {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 'none',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: '10px',
    height: '670px',
    justifyContent: 'center',
    overflow: 'visible',
    padding: '0',
    position: 'absolute',
    top: '-420px',
    transform: 'perspective(1200px)',
    width: '772px',
    zIndex: '0',
};

const RadialBlur = () => {
    const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <Box sx={{ ...(xs && { opacity: 0.33 }) }} >
            <Box sx={{ ...radSx, right: '0' }}>
                <Box
                    component='span'
                    sx={{
                        backgroundColor: '#371ae7',
                        borderRadius: '100%',
                        filter: 'blur(90px)',
                        flex: 'none',
                        height: '618px',
                        position: 'absolute',
                        right: '-283px',
                        top: 0,
                        width: '838px',
                        zIndex: 1,
                    }}
                />
                <Box
                    component='span'
                    sx={{
                        backgroundColor: '#371ae7',
                        borderRadius: '50%',
                        filter: 'blur(82px)',
                        flex: 'none',
                        height: '514px',
                        left: '-310px',
                        position: 'absolute',
                        top: '11px',
                        width: '791px',
                        zIndex: '1',
                    }}
                />
            </Box>

            <Box sx={{ ...radSx, left: '-155px' }}>
                <Box
                    component='span'
                    sx={{
                        backgroundColor: '#f8ff37',
                        borderRadius: '100%',
                        filter: 'blur(76px)',
                        flex: 'none',
                        height: '523px',
                        left: '-32px',
                        position: 'absolute',
                        top: 'calc(53.73134328358211% - 523px / 2)',
                        width: '536px',
                        zIndex: '1',
                    }} />
                <Box
                    component='span'
                    sx={{
                        backgroundColor: '#02ed2a',
                        borderRadius: '100%',
                        filter: 'blur(95px)',
                        flex: 'none',
                        height: '345px',
                        left: '365px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '123px',
                        width: '345px',
                        willChange: 'transform',
                        zIndex: '1',
                    }} />
            </Box>
        </Box>
    )
};


export default function Root() {
    const { width, height } = useWindowDimensions();

    return (
        <Box id='main' flexDirection='column' display='block' component='main' sx={{ width, height, overflowY: 'scroll', overflowX: 'hidden' }} >
            <Box sx={{ position: 'relative', flex: 1 }} >
                <RadialBlur />
                <Header />
                <Box pt='75px' ><Outlet /></Box>
                <Footer />
            </Box>
        </Box>
    );

};
